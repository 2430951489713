var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { EventManager, Inject, Service } from "@bluelibs/core";
import { Smart } from "@bluelibs/x-ui";
import { AcceptedLanguage, MerchantsBillingPackageType, SubmitLogInputAction, SubmitLogInputMicroservice, UserRole, } from "@root/api.types";
import { AppGuardian, AssetService } from "../services";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { PROJECTS_FIND_ONE } from "../queries/projectsFindOne.query";
import env from "@root/startup/env";
import autoBind from "auto-bind";
import { UISessionService } from "@bluelibs/x-ui-session-bundle";
import { I18NService } from "@bluelibs/x-ui-i18n-bundle";
import { XRouter } from "@bluelibs/x-ui-react-router-bundle";
import { createContext } from "react";
import { WishlistSmart } from "./Wishlist.smart";
import { CHAT_WITH_MERCHANT, GET_VR_AVATAR, SUBMIT_LOG } from "../mutations";
import { PROJECTS_GET_NEXT_ROOM_ACTION_LINK } from "../queries/projectsGetNextRoomLink.query";
export const ProjectSmartContext = createContext(null);
let ProjectSmart = class ProjectSmart extends Smart {
    constructor() {
        super();
        this.state = {};
        this.init();
        autoBind(this);
    }
    static getContext() {
        return ProjectSmartContext;
    }
    async init() {
        this.setState({
            isLoggedIn: undefined,
            isOwnerOfProject: undefined,
            isEmbedded: window.location !== window.parent.location,
            isFromMerchantsHeader: window.location.search.includes("fromMerchantsHeader"),
            hasReceivedInitFromMerchants: undefined,
            currentlySelectedAssetId: undefined,
            project: undefined,
            avatar: undefined,
            shop: undefined,
            isProductSelectedFromMerchants: false,
            isChatting: false,
            hasMicrophone: false,
            hasCamera: false,
            isWatching360: false,
            isLoadingProject: true,
            isLoadingAvatar: true,
            isLoadingShop: true,
            errorShop: null,
            linkName: undefined,
            role: null,
        });
    }
    get project() {
        return this.state.project;
    }
    get avatar() {
        return this.state.avatar;
    }
    set avatar(avatar) {
        this.state.avatar = avatar;
    }
    async setIsChatting() {
        const { hasChattedBefore } = this.state;
        this.updateState({ isChatting: true, hasChattedBefore: true });
        if (!hasChattedBefore) {
            this.apolloClient.mutate({
                mutation: CHAT_WITH_MERCHANT,
                variables: {
                    input: {
                        merchantId: this.uiSessionService.state.merchantId,
                    },
                },
            });
        }
    }
    async enterProject() {
        var _a, _b, _c;
        if (!this.state.linkName)
            return;
        try {
            const { data, error } = await this.apolloClient.query({
                query: PROJECTS_FIND_ONE(this.uiSessionService.state.currentLocale || AcceptedLanguage.en),
                fetchPolicy: "network-only",
                variables: {
                    input: {
                        linkName: this.state.linkName,
                        isMerchantMode: this.state.isEmbedded,
                    },
                },
            });
            this.updateState({
                errorProject: error,
                isLoadingProject: false,
                project: data === null || data === void 0 ? void 0 : data.EndUsersGetProject,
                role: (((_b = (_a = this.guardian.state.user) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b[0]) ||
                    UserRole.ENDUSER),
            });
        }
        catch (err) {
            this.updateState({
                errorProject: err,
                isLoadingProject: false,
            });
        }
        // Getting VR Avatar object from DB
        if (this.guardian.state.isLoggedIn) {
            try {
                const { data, error } = await this.apolloClient.query({
                    query: GET_VR_AVATAR,
                    fetchPolicy: "network-only",
                });
                this.updateState({
                    errorAvatar: error,
                    isLoadingAvatar: false,
                    avatar: (_c = data === null || data === void 0 ? void 0 : data.UsersVRAvatarsGet) !== null && _c !== void 0 ? _c : null,
                });
            }
            catch (err) {
                this.updateState({
                    errorAvatar: err,
                    isLoadingAvatar: false,
                });
            }
        }
    }
    async getNextRoomActionProjectLink(asset) {
        try {
            const { data, error: _ } = await this.apolloClient.query({
                query: PROJECTS_GET_NEXT_ROOM_ACTION_LINK,
                fetchPolicy: "network-only",
                variables: { input: { projectAssetInfoId: asset._id } },
            });
            return data.EndUsersGetNextRoomActionProjectLink;
        }
        catch (err) {
            return null;
        }
    }
    shouldShowAsset(asset) {
        if (asset.product)
            return true;
        if (this.guardian.state.isLoggedIn) {
            return this.state.isOwnerOfProject;
        }
        return false;
    }
    canUseVideo() {
        const { billing: { features, packageType }, } = this.state.project;
        const isVr = AFRAME.utils.device.checkHeadsetConnected() &&
            !AFRAME.utils.device.isMobile();
        if (isVr) {
            return false;
        }
        if (packageType === MerchantsBillingPackageType.TITAN) {
            return true;
        }
        return false;
    }
    canUseAudio() {
        const { billing: { features, packageType }, } = this.state.project;
        if ([
            MerchantsBillingPackageType.GOLD,
            MerchantsBillingPackageType.TITAN,
        ].includes(packageType)) {
            return true;
        }
        return false;
    }
    canUseChat() {
        const { billing: { features, packageType }, } = this.state.project;
        if (this.guardian.state.isLoggedIn)
            return false;
        return packageType !== MerchantsBillingPackageType.BRONZE;
    }
    isOwnerOfProject() {
        var _a, _b;
        return (this.state.project &&
            this.state.project.merchantId === ((_b = (_a = this.guardian.state.user) === null || _a === void 0 ? void 0 : _a.merchant) === null || _b === void 0 ? void 0 : _b._id));
    }
    async updatePositions(asset) {
        const targetElement = document.getElementById(`${asset._id}-entity`);
        const rigElement = document.getElementById("rig");
        const cameraElement = document.getElementById("player");
        const targetObject3D = targetElement.object3D;
        const boundingBox = new AFRAME.THREE.Box3().setFromObject(targetObject3D);
        const currentY = rigElement.object3D.position.y;
        const targetCenter = new AFRAME.THREE.Vector3();
        boundingBox.getCenter(targetCenter);
        const direction = new AFRAME.THREE.Vector3(0, 0, 1);
        direction.applyQuaternion(targetObject3D.quaternion);
        const teleportPosition = targetCenter.clone().add(direction);
        rigElement.object3D.position.set(teleportPosition.x, currentY, teleportPosition.z);
        cameraElement.removeAttribute("look-controls");
        const cameraPosition = new AFRAME.THREE.Vector3();
        cameraElement.object3D.getWorldPosition(cameraPosition);
        const lookDirection = targetCenter.clone().sub(cameraPosition).normalize();
        const lookRotation = new AFRAME.THREE.Euler().setFromVector3(lookDirection, "YXZ");
        cameraElement.setAttribute("rotation", {
            x: 0,
            y: AFRAME.THREE.MathUtils.radToDeg(lookRotation.y),
            z: 0,
        });
        cameraElement.setAttribute("look-controls", "enabled: true");
        // Somebody pls explain me why this one doesn't need degreesToRadians()...
        // await this.eventManager.emit(
        //   new PlayerTeleported({
        //     rotation: { x: 0, y: assetRotation.y, z: 0 },
        //   })
        // );
    }
    onAssetClickedVR(e) {
        const { projectAssetInfoId } = e.data;
        const asset = this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId);
        const assetElement = document.getElementById(`${asset._id}-entity`);
        console.log(assetElement);
    }
    onAssetClicked(e) {
        if (this.state.isWatching360)
            return;
        const { projectAssetInfoId } = e.data;
        const asset = this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId);
        this.updateState({
            currentlySelectedAssetId: this.state.currentlySelectedAssetId === projectAssetInfoId
                ? null
                : asset === null || asset === void 0 ? void 0 : asset._id,
        });
        this.sendMessageToParent({
            type: "asset-clicked",
            value: {
                projectAssetInfoId: projectAssetInfoId,
            },
        });
    }
    onProductPlaced(projectAssetInfo) {
        const { _id: projectAssetInfoId, product } = projectAssetInfo;
        const assetInfos = [...this.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === projectAssetInfoId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        oldAsset.product = product;
        oldAsset.mediaType = projectAssetInfo.mediaType;
        oldAsset.typeFor3D = projectAssetInfo.typeFor3D;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
        if (this.state.currentlySelectedAssetId) {
            this.updateState({
                currentlySelectedAssetId: oldAsset._id,
            });
        }
        this.updateState({
            isProductSelectedFromMerchants: false,
        });
    }
    onProductRemoved(projectAssetInfoId) {
        const assetInfos = [...this.state.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === projectAssetInfoId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        delete oldAsset.product;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
    }
    onTeleportToAsset(projectAssetInfoId) {
        this.updatePositions(this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId));
    }
    onAssetPositionsChange(positions, assetId) {
        const entity = this.assetService.findWorldAssetEntityById(assetId);
        const originalPositions = this.project.assetInfos.find((info) => info._id === assetId).assetPosition.coordinates;
        this.assetService.updatePositionsForAsset(entity, this.assetService.calculatePositionWithDelta(positions, originalPositions));
    }
    onAssetPositionsUpdated(deltaPositions, assetId) {
        // TODO: update the position in the item, in state
        const assetInfos = [...this.state.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === assetId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        oldAsset.deltaCoordinates = deltaPositions;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
    }
    async onLogin() {
        const isOwnerOfProject = this.state.isOwnerOfProject
            ? true
            : this.isOwnerOfProject();
        this.updateState({
            isOwnerOfProject,
        });
        if (!this.wishlistSmart.state.initialised) {
            await this.wishlistSmart.init();
        }
        this.wishlistSmart.updateWishlist();
    }
    async onLogout() {
        await this.guardian.logout();
        this.updateState({
            isLoggedIn: false,
            isOwnerOfProject: undefined,
        });
        this.enterProject();
    }
    switchCamera() {
        this.updateState({
            hasCamera: !this.state.hasCamera,
        });
    }
    switchMicrophone() {
        this.updateState({
            hasMicrophone: !this.state.hasMicrophone,
        });
    }
    onProductSelectedFromMerchants(isProductSelectedFromMerchants) {
        this.updateState({ isProductSelectedFromMerchants });
    }
    degreesToRadians(degrees) {
        return degrees * (Math.PI / 180);
    }
    sendMessageToParent(data) {
        if (!this.state.isEmbedded)
            return;
        parent.postMessage(data, {
            targetOrigin: env.MERCHANTS_APP_URL,
        });
    }
    onLanguageChange(lang) {
        this.uiSessionService.set("currentLocale", lang, { persist: true });
        this.i18nService.setLocale(lang);
        this.router.history.push({
            ...this.router.history.location,
            search: `?lang=${lang}`,
        });
    }
    switchIsChatting() {
        this.updateState({
            isChatting: !this.state.isChatting,
        });
    }
    async messageEventListener(event) {
        if (event.origin !== env.MERCHANTS_APP_URL)
            return;
        const data = event.data;
        switch (data.type) {
            case "switchCamera":
                this.switchCamera();
                break;
            case "switchMicrophone":
                this.switchMicrophone();
                break;
            case "product-placed":
                this.onProductPlaced(data.value.projectAssetInfo);
                break;
            case "set-auth-token":
                await this.guardian.storeToken(data.token);
                await this.guardian.load();
                this.updateState({
                    hasReceivedInitFromMerchants: true,
                });
                break;
            case "product-removed":
                this.onProductRemoved(data.value.projectAssetInfoId);
                break;
            case "teleport-to-asset":
                this.onTeleportToAsset(data.assetInfoId);
                break;
            case "asset-clicked":
                this.onProductSelectedFromMerchants(data.selected);
                break;
            case "positions-changed":
                this.onAssetPositionsChange(data.positions, data.assetId);
                break;
            case "positions-updated":
                this.onAssetPositionsUpdated(data.positions, data.assetId);
                break;
            case "language-changed":
                this.onLanguageChange(data.language);
                break;
        }
    }
    async submitLog(primaryData) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        const getNavigatorInfo = () => {
            const navigatorInfo = {};
            try {
                for (const nav in navigator) {
                    if (typeof navigator[nav] !== "object" || nav === "userAgentData") {
                        navigatorInfo[nav] = navigator[nav];
                    }
                }
            }
            catch { }
            return navigatorInfo;
        };
        const getScreenInfo = () => {
            const screenInfo = {};
            try {
                for (const loc in screen) {
                    screenInfo[loc] = screen[loc];
                }
            }
            catch { }
            return screenInfo;
        };
        try {
            const secondaryData = {
                project: {
                    id: (_a = this.project) === null || _a === void 0 ? void 0 : _a._id,
                    name: (_b = this.project) === null || _b === void 0 ? void 0 : _b.name,
                },
                world: {
                    id: (_d = (_c = this.project) === null || _c === void 0 ? void 0 : _c.world) === null || _d === void 0 ? void 0 : _d._id,
                    name: (_g = (_f = (_e = this.project) === null || _e === void 0 ? void 0 : _e.world) === null || _f === void 0 ? void 0 : _f.name) === null || _g === void 0 ? void 0 : _g.text,
                },
                guardian: {
                    state: {
                        isLoggedIn: (_j = (_h = this.guardian) === null || _h === void 0 ? void 0 : _h.state) === null || _j === void 0 ? void 0 : _j.isLoggedIn,
                        hasInvalidToken: (_l = (_k = this.guardian) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 : _l.hasInvalidToken,
                        user: {
                            id: (_p = (_o = (_m = this.guardian) === null || _m === void 0 ? void 0 : _m.state) === null || _o === void 0 ? void 0 : _o.user) === null || _p === void 0 ? void 0 : _p._id,
                            email: (_s = (_r = (_q = this.guardian) === null || _q === void 0 ? void 0 : _q.state) === null || _r === void 0 ? void 0 : _r.user) === null || _s === void 0 ? void 0 : _s.email,
                            fullName: (_v = (_u = (_t = this.guardian) === null || _t === void 0 ? void 0 : _t.state) === null || _u === void 0 ? void 0 : _u.user) === null || _v === void 0 ? void 0 : _v.fullName,
                            role: (_z = (_y = (_x = (_w = this.guardian) === null || _w === void 0 ? void 0 : _w.state) === null || _x === void 0 ? void 0 : _x.user) === null || _y === void 0 ? void 0 : _y.roles) === null || _z === void 0 ? void 0 : _z[0],
                        },
                    },
                },
                state: {
                    isEmbedded: (_0 = this.state) === null || _0 === void 0 ? void 0 : _0.isEmbedded,
                    isFromMerchantsHeader: (_1 = this.state) === null || _1 === void 0 ? void 0 : _1.isFromMerchantsHeader,
                    isProductSelectedFromMerchants: (_2 = this.state) === null || _2 === void 0 ? void 0 : _2.isProductSelectedFromMerchants,
                    isChatting: (_3 = this.state) === null || _3 === void 0 ? void 0 : _3.isChatting,
                    hasMicrophone: (_4 = this.state) === null || _4 === void 0 ? void 0 : _4.hasMicrophone,
                    hasCamera: (_5 = this.state) === null || _5 === void 0 ? void 0 : _5.hasCamera,
                },
                uiSessionServiceState: {
                    projectLinkName: (_7 = (_6 = this.uiSessionService) === null || _6 === void 0 ? void 0 : _6.state) === null || _7 === void 0 ? void 0 : _7.projectLinkName,
                    currentLocale: (_9 = (_8 = this.uiSessionService) === null || _8 === void 0 ? void 0 : _8.state) === null || _9 === void 0 ? void 0 : _9.currentLocale,
                },
                canUseVideo: this.canUseVideo(),
                canUseAudio: this.canUseAudio(),
                canUseChat: this.canUseChat(),
                isOwnerOfProject: this.isOwnerOfProject(),
                vrMicroservice: {
                    buildDate: env === null || env === void 0 ? void 0 : env.BUILD_DATE,
                    buildVersion: env === null || env === void 0 ? void 0 : env.BUILD_VERSION,
                },
                window: {
                    navigator: getNavigatorInfo(),
                    screen: getScreenInfo(),
                    performance,
                    location,
                },
            };
            const dataToLog = {
                primary: primaryData,
                secondary: secondaryData,
            };
            const res = await this.apolloClient.mutate({
                mutation: SUBMIT_LOG,
                variables: {
                    input: {
                        action: SubmitLogInputAction.INFO,
                        microservice: SubmitLogInputMicroservice.VR,
                        data: dataToLog,
                    },
                },
            });
            return res;
        }
        catch (err) {
            console.error({ msg: "Error in SubmitLog", err });
            try {
                await this.apolloClient.mutate({
                    mutation: SUBMIT_LOG,
                    variables: {
                        input: {
                            action: SubmitLogInputAction.EXCEPTION,
                            microservice: SubmitLogInputMicroservice.VR,
                            data: { msg: "Error in SubmitLog", err: err === null || err === void 0 ? void 0 : err.toString() },
                        },
                    },
                });
            }
            catch { }
            return null;
        }
    }
};
__decorate([
    Inject(() => AppGuardian),
    __metadata("design:type", typeof (_a = typeof AppGuardian !== "undefined" && AppGuardian) === "function" ? _a : Object)
], ProjectSmart.prototype, "guardian", void 0);
__decorate([
    Inject(() => EventManager),
    __metadata("design:type", typeof (_b = typeof EventManager !== "undefined" && EventManager) === "function" ? _b : Object)
], ProjectSmart.prototype, "eventManager", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_c = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _c : Object)
], ProjectSmart.prototype, "apolloClient", void 0);
__decorate([
    Inject(() => AssetService),
    __metadata("design:type", typeof (_d = typeof AssetService !== "undefined" && AssetService) === "function" ? _d : Object)
], ProjectSmart.prototype, "assetService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_e = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _e : Object)
], ProjectSmart.prototype, "uiSessionService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_f = typeof I18NService !== "undefined" && I18NService) === "function" ? _f : Object)
], ProjectSmart.prototype, "i18nService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_g = typeof XRouter !== "undefined" && XRouter) === "function" ? _g : Object)
], ProjectSmart.prototype, "router", void 0);
__decorate([
    Inject(() => WishlistSmart),
    __metadata("design:type", typeof (_h = typeof WishlistSmart !== "undefined" && WishlistSmart) === "function" ? _h : Object)
], ProjectSmart.prototype, "wishlistSmart", void 0);
ProjectSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [])
], ProjectSmart);
export { ProjectSmart };
