import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from "react";
import { AssetMediaRenderType, AssetMediaTypeFor3D, } from "@root/api.types";
import { AvatarTemplate } from "./AvatarTemplate";
export const AssetItems = ({ project, avatarUrl }) => {
    const { world, assetInfos } = project;
    return (_jsxs("a-assets", { children: [_jsx(AvatarTemplate, { avatarUrl: avatarUrl, templateId: "avatar-template" }, void 0), _jsx("template", Object.assign({ id: "rig-template" }, { children: _jsx("a-entity", {}, void 0) }), void 0), _jsx("a-asset-item", { id: "place-button-obj", src: "/public/3d-items/obj-mtl/PlaceButton.obj" }, void 0), _jsx("a-asset-item", { id: "place-button-mtl", src: "/public/3d-items/obj-mtl/PlaceButton.mtl" }, void 0), _jsx("img", { id: "video-icon-on", src: "/public/vr/icon-video-on.svg" }, void 0), _jsx("img", { id: "video-icon-off", src: "/public/vr/icon-video-off.svg" }, void 0), _jsx("img", { id: "voice-icon-on", src: "/public/vr/icon-mic-on.svg" }, void 0), _jsx("img", { id: "voice-icon-off", src: "/public/vr/icon-mic-off.svg" }, void 0), _jsx("img", { id: "basket-icon", src: "/public/vr/icon-basket.svg" }, void 0), _jsx("img", { id: "leave-icon", src: "/public/vr/icon-leave.svg" }, void 0), _jsx("img", { id: "list-icon", src: "/public/vr/icon-list.svg" }, void 0), _jsx("a-asset-item", { id: "world", src: world.worldFile.downloadUrl }, void 0), world.meshFile ? (_jsx("a-asset-item", { id: "nav-mesh", src: world.meshFile.downloadUrl }, void 0)) : null, assetInfos.map((assetInfo) => {
                var _a, _b;
                const hasProductPlaced = Boolean(assetInfo.product);
                const id = (_a = assetInfo._id) !== null && _a !== void 0 ? _a : assetInfo.assetPosition._id;
                const imageProps = {
                    key: id,
                    crossOrigin: "anonymous",
                    id,
                    className: "asset-item",
                };
                const media = hasProductPlaced
                    ? assetInfo.product.media
                    : assetInfo.assetPosition.media;
                const mediaType = hasProductPlaced
                    ? assetInfo.mediaType
                    : assetInfo.assetPosition.mediaType;
                const typeFor3D = hasProductPlaced
                    ? assetInfo.typeFor3D
                    : assetInfo.assetPosition.typeFor3D;
                if (mediaType === AssetMediaRenderType.IMAGE_360) {
                    const src = media.image360Files[0].downloadUrl;
                    return _jsx("img", Object.assign({}, imageProps, { src: src }), void 0);
                }
                if (mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL) {
                    return (_jsx(Fragment, { children: media.image360Files.map((file, idx) => (_jsx("img", { id: `${id}-${idx}`, crossOrigin: "anonymous", src: file.downloadUrl }, `${id}-${idx}`))) }, media.image360Files.reduce((prev, curr) => prev + curr._id, "")));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_2D) {
                    return (_jsx("img", Object.assign({}, imageProps, { src: media.image2dFiles[0].downloadUrl }), void 0));
                }
                if (mediaType === AssetMediaRenderType.IMAGE_2D_CAROUSEL) {
                    return (_jsx(Fragment, { children: media.image2dFiles.map((file, idx) => {
                            return (_jsx("img", { id: `${id}-${idx}`, crossOrigin: "anonymous", src: file.downloadUrl }, `${id}-${idx}`));
                        }) }, media.image2dFiles.reduce((prev, curr) => prev + curr._id, "")));
                }
                if (mediaType === AssetMediaRenderType.ITEM_3D) {
                    if (typeFor3D === AssetMediaTypeFor3D.OBJ_MTL) {
                        return (_jsxs(Fragment, { children: [_jsx("a-asset-item", { id: `${id}-obj`, src: media.image3dOBJ.downloadUrl }, `${id}-obj`), _jsx("a-asset-item", { crossOrigin: "anonymous", id: `${id}-mtl`, src: media.image3dMTL.downloadUrl }, `${id}-mtl`)] }, `${id}-fragment`));
                    }
                    if (typeFor3D === AssetMediaTypeFor3D.GLB) {
                        return (_jsx("a-asset-item", { id: id, src: media.image3DGLB.downloadUrl }, id));
                    }
                }
                if (mediaType === AssetMediaRenderType.VIDEO) {
                    const mediaUrl = media.videoUrl || ((_b = media.videoUpload) === null || _b === void 0 ? void 0 : _b.downloadUrl);
                    if (mediaUrl) {
                        return _jsx("a-asset-item", { id: id, src: mediaUrl }, id);
                    }
                }
            })] }, void 0));
};
