export default {
    // App
    MERCHANTS_APP_URL: process.env.MERCHANTS_APP_URL,
    API_URL: process.env.API_URL,
    API_BASE_URL: process.env.API_BASE_URL,
    LANDING_APP_URL: process.env.LANDING_APP_URL,
    // Tawk
    TAWK_PROPERTY_ID: process.env.TAWK_PROPERTY_ID,
    TAWK_WIDGET_ID: process.env.TAWK_WIDGET_ID,
    // Regionalis
    REGIONALIS_APP_URL: process.env.REGIONALIS_APP_URL,
    // Webrtc
    WEBRTC_SERVER_URL: process.env.WEBRTC_SERVER_URL,
    // Build
    BUILD_DATE: process.env.BUILD_DATE,
    BUILD_VERSION: process.env.BUILD_VERSION,
};
